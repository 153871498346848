const carData = [
    {
        img_name: 'shudaoxing.png',
		link: '/shudaoxing/calculator',
    },
    {
        img_name: 'yonghengbei.png',
		link: '/yonghengbei/calculator',
    }
];
export default carData;