const carTeam = [
    {
        img_name: '1.jpg',
    },
    {
        img_name: '2.jpg',
    },
    {
        img_name: '3.jpg',
    }
];
export default carTeam;